import { Button, Grid, Text } from 'components'
import './side_menu.scss'


const KPIS_charts = [
    { name: "Ventas por segmento", id: "VentasSegmento"},
    { name: "Clientes abonados", id: "ClientesAbonados"},
    { name: "Tasa de retención de clientes", id: "TasaRetencion"},
    { name: "Gastos por categoria", id: "GastosCategoria"},
    { name: "Gastos por departamento", id: "GastosDepartamento"},
    { name: "Histórico de gastos mensuales", id: "GastosMensuales"},
    { name: "Rentabilidad Anual", id: "RentabilidadAnual"},
]

const SideMenu = ({ setSelectedChart }) => {
    const handleValue = (value) => {
        setSelectedChart(value)
    }
    return (
        <Grid className="finances__side_menu" maxWidth="300px" itemsX="center" contentY="start" gap="24px" padding="24px 0px">
            <Grid columns="auto auto" gap="12px" padding="0px 10px">
                <Button>Finanzas KPIS</Button>
            </Grid>
            <Grid w100 className="section_values__container">
                {
                    KPIS_charts.map((value, i) => (
                        <Grid
                            className='section_values__option'
                            padding="14px"
                            columns="1fr auto"
                            itemsY="center"
                            gap="10px"
                            maxWidth="275px"
                            key={i}
                            onClick={() => { handleValue(value.id) }}>
                            <Text align="center">{value.name}</Text>
                            <span />
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default SideMenu
