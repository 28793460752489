
import axios from 'axios';
import { useEffect, useState } from 'react';

import { Button, Grid, Text } from 'components';
import { useNavigate, useOutletContext } from 'react-router-dom';


import { financesChartDefaultTitles } from '../utils/generalFunctions';
import './chart_finances_content.scss';


const FinanceChartContent = () => {

    /*----------- ROUTING -----------*/
    const navigate = useNavigate()
    const [ selectedChart ] = useOutletContext()
    /*----------- STATE -----------*/
    const [chartContent, setchartContent] = useState("")

    /*----------- FUNCTIONS -----------*/
    const getchartContent = async () => {
        try {
            const response = await axios.get(`/finances/${selectedChart}/charts`, { appSpinner: true })
            console.log(response)
            if (response.data) {
                setchartContent(response.data.image)
            }
        } catch (error) {
            console.error('GET_CONTROL_DATA_ERROR', error)
        }
    }

    /*----------- EFFECTS -----------*/
    useEffect(() => {
        setTimeout(() => {
            getchartContent()
        }) // setTimeout to make work appSpinner
        // eslint-disable-next-line
    }, [selectedChart])

    /*----------- RENDER -----------*/
    return (
        <Grid className="finances__chart_container" w100 columns="auto" itemsX="center" contentY="start" gap="0.7em" padding="24px 0px">
            <Grid columns="1fr auto 1fr" w100 gap="24px">
                <Button justifySelf="start" onClick={() => { navigate('/') }}>Regresar</Button>
                <Text size="7" bold>{financesChartDefaultTitles[selectedChart]}</Text>
                <Grid></Grid>
            </Grid>

            <img alt="Chart content for this specific section" className="finances__chart_content" src={`data:image/png;base64,${chartContent}`} />
        </Grid>
    )
}

export default FinanceChartContent
