
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Grid, } from 'components'

import './finances.scss'

import SideMenu from './SideMenu/SideMenu'

const Finances = () => {

    const [selectedChart, setSelectedChart] = useState("VentasSegmento")
    return (
        <Grid className="finances" columns="auto 1fr" itemsX="center" contentY="start" gap="24px" rows="100%">
            <SideMenu {...{ selectedChart, setSelectedChart}} />
            <Outlet context={[selectedChart]} />
        </Grid>
    )
}

export default Finances
